<template>
  <v-col
    cols="12"
    sm="10"
    md="10"
    lg="8"
    xl="7"
    v-if="!isEnd"
    class="fantasy__answer d-flex flex-column justify-center align-center pa-0"
    :class="started ? '' : 'top-margin'"
  >
    <RepeatButton
      v-if="started"
      :isDisabled="disableButtons"
      @click.native="repeatQuestion"
    />

    <StartTestButton
      v-if="!started"
      @start="
        started = true;
        playIntro();
      "
    />

    <template v-if="started">
      <FantasyWordQuestion
        :question="currentQuestion.solution"
        class="fantasy__question"
      />

      <v-col
        cols="12"
        class="fantasy__options d-flex justify-center align-start mt-6 pa-0 flex-grow-4"
      >
        <FantasyWordAnswerOption
          :key="`option-${0}`"
          :image="inactiveImages[0]"
          :activeImage="activeMax"
          :showBubble="showMaxBubble"
          @click.native="answerQuestion(currentQuestion.options[0])"
          :class="{
            disabledButton:
              disableButtons &&
              !(
                currentQuestion.options[0] === currentQuestion.solution &&
                enableSolution
              ),
            solution: currentQuestion.options[0] === currentQuestion.solution,
            wrongAnswer: currentQuestion.options[0] != currentQuestion.solution,
          }"
          :isTutorial="true"
          :format="format"
          :answerLocked="answerPlaying"
        />
        <FantasyWordAnswerOption
          :key="`option-${1}`"
          :image="inactiveImages[1]"
          :activeImage="activeLisa"
          :showBubble="showLisaBubble"
          @click.native="answerQuestion(currentQuestion.options[1])"
          :class="{
            disabledButton:
              disableButtons &&
              !(
                currentQuestion.options[1] === currentQuestion.solution &&
                enableSolution
              ),
            solution: currentQuestion.options[1] === currentQuestion.solution,
            wrongAnswer: currentQuestion.options[1] != currentQuestion.solution,
          }"
          :format="format"
          :answerLocked="answerPlaying"
        />
        <FantasyWordAnswerOption
          :key="`option-${2}`"
          :image="inactiveImages[2]"
          :activeImage="activeBoth"
          @click.native="answerQuestion(currentQuestion.options[2])"
          :class="{
            disabledButton:
              disableButtons &&
              !(
                currentQuestion.options[2] === currentQuestion.solution &&
                enableSolution
              ),
            solution: currentQuestion.options[2] === currentQuestion.solution,
            wrongAnswer: currentQuestion.options[2] != currentQuestion.solution,
          }"
          :format="format"
          :answerLocked="answerPlaying"
        />
      </v-col>
    </template>
    <audio :src="audioSrc" ref="currentAudio" @ended="playSound"></audio>
  </v-col>
</template>

<script lang="ts">
import FantasyModule from '@/store/modules/FantasyModule';
import UserModule from '@/store/modules/UserModule';
import { FantasyWordQuestionType } from '@/types';
import Component from 'vue-class-component';
import { Ref } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import RepeatButton from '../../base/RepeatButton.vue';
import StartTestButton from '../../base/StartTestButton.vue';
import TutorialEndEmoji from '../../base/TutorialEndEmoji.vue';
import FantasyWordAnswerOption from './FantasyWordAnswerOption.vue';
import FantasyWordQuestion from './FantasyWordQuestion.vue';
import TutorialMixin from '../TutorialMixin';

@Component({
  components: {
    FantasyWordQuestion,
    FantasyWordAnswerOption,
    TutorialEndEmoji,
    RepeatButton,
    StartTestButton,
  },
})
export default class FantasyTutorialContainer extends TutorialMixin {
  fantasyModule: FantasyModule = getModule(FantasyModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  idx = 0;
  answered = 0;
  totalQuestions = this.fantasyModule.tutorialData.length;
  disableButtons = true;
  enableSolution = false;
  started = false;

  activeImages = [
    '/images/fantasy/Max',
    '/images/fantasy/Lisa',
    '/images/fantasy/Max-and-Lisa',
  ];
  inactiveImages = [
    '/images/fantasy/Max_ALT',
    '/images/fantasy/Lisa_ALT',
    '/images/fantasy/Max-and-Lisa_ALT',
  ];

  @Ref() currentAudio!: HTMLAudioElement;
  audioSrc = '/audio/click.mp3';
  activeMax = '';
  showMaxBubble = false;
  activeLisa = '';
  showLisaBubble = false;
  activeBoth = '';
  tutorialEndSound?: HTMLAudioElement;
  maxReads?: HTMLAudioElement;
  lisaReads?: HTMLAudioElement;
  readCorrectly?: HTMLAudioElement;
  clickCorrectImage?: HTMLAudioElement;
  wrongMaxCorrect?: HTMLAudioElement;
  wrongLisaCorrect?: HTMLAudioElement;
  maxMo?: HTMLAudioElement;
  maxSchoni?: HTMLAudioElement;
  lisaSi?: HTMLAudioElement;
  lisaRula?: HTMLAudioElement;
  super?: HTMLAudioElement;

  // in ms
  delayBetweenMaxAndLisa = 2000;

  answerPlaying = false;
  canPlayTimeout = 0;

  get audios() {
    return {
      bothRead: '/audio/tutorials/u2/HELP256.mp3',
      maxReads: '/audio/tutorials/u2/HELP291.mp3',
      lisaReads: '/audio/tutorials/u2/HELP3833.mp3',
      clickCorrectImage: '/audio/tutorials/u2/HELP269.mp3',
      clickOrangeAnswer: '/audio/tutorials/u2/HELP264.mp3',
      whoReadCorrectly: '/audio/tutorials/u2/HELP283.mp3',
      wellDone: '/audio/tutorials/u2/HELP291.mp3',
      super: '/audio/tutorials/u2/HELP3833.mp3',
      wrongMaxCorrect: '/audio/tutorials/u2/HELP258.mp3',
      wrongLisaCorrect: '/audio/tutorials/u2/HELP257.mp3',
      readyGo: '/audio/tutorials/u2/HELP3836.mp3',
      mo: `/audio/fantasy/tutorial/mo.mp3`,
      schoni: `/audio/fantasy/tutorial/schoni.mp3`,
      si: `/audio/fantasy/tutorial/si.mp3`,
      rula: `/audio/fantasy/tutorial/rula.mp3`,
      default: '/audio/click.mp3',
    };
  }

  get currentQuestion(): FantasyWordQuestionType {
    return this.fantasyModule.tutorialQuestions[this.idx];
  }

  get isEnd(): boolean {
    return this.answered === this.totalQuestions;
  }

  get format(): string {
    return this.fantasyModule.imageFormat;
  }

  repeatQuestion(): void {
    this.userModule.stopActiveAudio();
    //reset images
    this.activeMax = '';
    this.activeLisa = '';
    this.activeBoth = '';
    this.disableButtons = true;
    this.enableSolution = false;
    //reset and play whole intro again
    this.idx = 0;
    this.answered = 0;
    this.audioSrc = this.audios.default;
    // enable answer action
    this.answerPlaying = false;
    this.playSound();
  }

  onCanPlay() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout = window.setTimeout(
      this.onCanPlayThrough.bind(this),
      6 * 1000,
    );
  }

  onCanPlayThrough() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
    this.canPlayTimeout && (this.canPlayTimeout = 0);
    this.currentAudio?.removeEventListener('canplay', this.onCanPlay);
    this.currentAudio?.removeEventListener(
      'canplaythrough',
      this.onCanPlayThrough,
    );
    this.userModule.setActiveAudio(this.currentAudio);

    this.currentAudio?.addEventListener('play', () => {
      // Highlight Max Bubble
      if (
        this.audioSrc === this.audios.mo ||
        this.audioSrc === this.audios.schoni
      ) {
        this.activeMax = this.activeImages[0];
        this.showMaxBubble = true;
      }
      // Highlight Lisa Bubble
      if (
        this.audioSrc === this.audios.si ||
        this.audioSrc === this.audios.rula
      ) {
        this.activeLisa = this.activeImages[1];
        this.showLisaBubble = true;
      }
      // Highlight according to audio
      if (this.audioSrc === this.audios.whoReadCorrectly) {
        this.activeMax = '';
        this.activeLisa = '';
        this.activeBoth = '';
        //Max active
        setTimeout(() => {
          this.activeMax = this.activeImages[0];
          this.activeLisa = '';
        }, 2200);
        //Lisa active
        setTimeout(() => {
          this.activeMax = '';
          this.activeLisa = this.activeImages[1];
        }, 4000);
        setTimeout(() => {
          this.activeMax = '';
          this.activeLisa = '';
          this.activeBoth = this.activeImages[2];
        }, 6000);
      }

      // INFO highlight solution after wrong answer
      if (
        this.audioSrc === this.audios.wrongMaxCorrect ||
        this.audioSrc === this.audios.wrongLisaCorrect
      ) {
        setTimeout(async () => {
          const solution = document.getElementsByClassName('solution')![0];
          solution?.firstElementChild?.classList.add('highlight-fantasy');
        }, 2000);
      }
    });

    // INFO delay playing according to audio
    if (this.audioSrc === this.audios.si) {
      setTimeout(() => {
        this.currentAudio?.play();
      }, this.delayBetweenMaxAndLisa);
    } else if (this.audioSrc === this.audios.super) {
      setTimeout(() => {
        this.currentAudio?.play();
      }, 1000);
    } else if (this.audioSrc === this.audios.schoni) {
      setTimeout(() => {
        // Remove correct answer highlight
        const solution = document.getElementsByClassName('solution')![0];
        solution?.firstElementChild?.classList.remove('highlight-fantasy');
        // Unhighlight images
        this.activeMax = '';
        this.activeLisa = '';
        this.activeBoth = '';
        // Go further in tutorial
        this.idx++;
        this.answered++;
        this.currentAudio?.play();
      }, 2000);
    } else {
      this.currentAudio?.play();
    }
  }

  addEventListeners(): void {
    this.currentAudio?.addEventListener('canplay', this.onCanPlay);
    this.currentAudio?.addEventListener(
      'canplaythrough',
      this.onCanPlayThrough,
    );
  }

  playSound() {
    switch (this.audioSrc) {
      case this.audios.default:
        this.audioSrc = this.audios.bothRead;
        this.addEventListeners();
        break;
      case this.audios.bothRead:
        this.audioSrc = this.audios.mo;
        this.addEventListeners();
        break;
      case this.audios.mo:
        // Unhighlight Max Bubble
        this.audioSrc = this.audios.si;
        this.showMaxBubble = false;
        this.activeMax = '';
        this.addEventListeners();
        break;
      case this.audios.si:
        // Unhighlight Lisa Bubble
        this.showLisaBubble = false;
        this.activeLisa = '';
        this.audioSrc = this.audios.whoReadCorrectly;
        this.addEventListeners();
        break;
      case this.audios.whoReadCorrectly:
        // Highlight all images
        this.activeMax = this.activeImages[0];
        this.activeLisa = this.activeImages[1];
        this.activeBoth = this.activeImages[2];
        this.audioSrc = this.audios.clickCorrectImage;
        this.addEventListeners();
        break;
      case this.audios.clickCorrectImage:
        // Enable buttons after "Klicke auf das richtige Bild"
        this.answerPlaying = false;
        this.disableButtons = false;
        break;
      case this.audios.super:
        // Info if last question, update here to go to end
        // first question updates on play to have a short delay between the questions
        if (this.idx === 1) {
          setTimeout(() => {
            this.idx++;
            this.answered++;
            if (this.isEnd) {
              if (this.isTutorialOnlyVersion) {
                this.redirectOnTutorialOnly();
              } else {
                this.userModule.setActiveAudio(this.tutorialEndSound!);
                this.fantasyModule.finishedTutorial();
                setTimeout(() => {
                  // Remove correct answer highlight
                  const solution =
                    document.getElementsByClassName('solution')![0];
                  solution?.firstElementChild?.classList.remove(
                    'highlight-fantasy',
                  );
                  this.tutorialEndSound!.play();
                }, 1000);
              }
            }
          }, 1500);
        } else {
          // enable answer question again
          this.answerPlaying = false;
          this.disableButtons = true;
          this.enableSolution = false;
          // play next question
          this.audioSrc = this.audios.schoni;
          this.addEventListeners();
        }

        break;
      case this.audios.schoni:
        // Unhighlight Max Bubble
        this.showMaxBubble = false;
        this.activeMax = '';
        this.audioSrc = this.audios.rula;
        this.addEventListeners();
        break;
      case this.audios.rula:
        // Unhighlight Lisa Bubble
        this.showLisaBubble = false;
        this.activeLisa = '';
        this.audioSrc = this.audios.whoReadCorrectly;
        this.addEventListeners();
        break;
      case this.audios.wrongMaxCorrect:
        this.audioSrc = this.audios.schoni;
        this.addEventListeners();
        break;
      case this.audios.wrongLisaCorrect:
        if (this.isTutorialOnlyVersion) {
          this.redirectOnTutorialOnly();
        } else {
          setTimeout(() => {
            // Remove correct answer highlight
            const solution = document.getElementsByClassName('solution')![0];
            solution?.firstElementChild?.classList.remove('highlight-fantasy');
            // Unhighlight images
            this.activeMax = '';
            this.activeLisa = '';
            this.activeBoth = '';
            // Go further in tutorial
            this.idx++;
            this.answered++;

            this.userModule.setActiveAudio(this.tutorialEndSound!);
            this.fantasyModule.finishedTutorial();
            setTimeout(() => {
              this.tutorialEndSound!.play();
            }, 1000);
          }, 2000);
        }
        break;
      default:
        break;
    }
  }

  playIntro(): void {
    if (!this.tutorialEndSound) {
      this.tutorialEndSound = new Audio(this.audios.readyGo);
    }
    this.playSound();
  }

  playCorrectAnswer(): void {
    this.audioSrc = this.audios.super;
    this.addEventListeners();
  }

  playWrongAnswer(): void {
    const maxCorrect =
      this.currentQuestion.options[0] === this.currentQuestion.solution;
    this.disableWrongImages(this.currentQuestion.solution);

    if (maxCorrect) {
      this.audioSrc = this.audios.wrongMaxCorrect;
    } else {
      this.audioSrc = this.audios.wrongLisaCorrect;
    }

    this.addEventListeners();
  }

  disableWrongImages(solution: string): void {
    const maxCorrect = this.currentQuestion.options[0] === solution;

    //reset all
    this.activeMax = '';
    this.activeLisa = '';
    this.activeBoth = '';

    //only highlight solution image
    if (maxCorrect) {
      this.activeMax = this.activeImages[0];
    } else {
      this.activeLisa = this.activeImages[1];
    }
    this.disableButtons = true;
  }

  answerQuestion(answer: string): void {
    if (this.answerPlaying) return;
    this.answerPlaying = true;
    this.disableButtons = true;
    this.enableSolution = false;
    if (answer === this.currentQuestion.solution) {
      this.playCorrectAnswer();
    } else {
      this.playWrongAnswer();
    }
  }

  beforeDestroy() {
    this.canPlayTimeout && clearTimeout(this.canPlayTimeout);
  }
}
</script>

<style scoped lang="scss">
.fantasy {
  &__question {
    max-height: 25%;
  }
  &__answer {
    width: 100%;
    height: 8%;
  }

  &__options {
    max-height: 68%;
  }
}

.wrong {
  cursor: default !important;
  pointer-events: none;
}

.enableSolution {
  cursor: pointer !important;
  pointer-events: auto !important;
}

.top-margin {
  margin-top: 8%;
}

.hide {
  display: none;
}
</style>
